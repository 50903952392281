<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs12 no-padding">
						<h1>Dashboard</h1>
					</div>
					<!-- <div class="flex xs6 no-padding text-right">
               <br><br>               
               <button
                  v-on:click="showSector(emptySector, 'add')"
                  class="btn btn-change">
                  ADICIONAR REGIÃO
               </button>
            </div> -->
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<h4></h4>
			</div>
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row">
									<div class="sets-list va-row">
										<div class="va-row">
											<div class="flex xs3 no-padding-left">
												<div class="input-group">
													<label>Status</label>
													<select-normal
														:items="listStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectStatus"
														@change="onChangeFilterStatus($event)"
													/>
												</div>
											</div>

											<div class="flex xs3">
												<div class="input-group">
													<label>Pagamento</label>
													<select-normal
														:items="listPayments"
														:value="''"
														label="Forma de pagamento"
														name="typeName"
														v-model="selectPayments"
														@change="onChangeFilterPayment($event)"
													/>
												</div>
											</div>

											<div class="flex xs3">
												<label>Faturamento</label>
												<div class="input-group">
													<select-normal
														:items="listPaymentStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectPaymentStatus"
														@change="onChangeFilterPaymentStatus($event)"
													/>
												</div>
											</div>

											<div class="flex xs3 no-padding-right">
												<label>Tipo de contrato</label>
												<div class="input-group">
													<select-normal
														:items="listContracts"
														:value="''"
														label="Selecione um contrato"
														name="typeName"
														v-model="selectContracts"
														@change="onChangeFilterContracts($event)"
													/>
												</div>
											</div>
										</div>
									</div>

									<div
										class="sets-list table-list va-row"
										v-if="list.length > 0"
									>
										<div class="va-row header no-padding">
											<div class="flex xs5"></div>
											<div
												class="fflex xs1 text-center"
												v-if="!consultantCredential.settings.hide_payment_data"
											>
												Pagamento
											</div>
											<div class="flex xs1 text-center">Aprovação</div>
											<div class="flex xs1 text-center">Produção</div>
											<div class="flex xs1 text-center">Entrega</div>
											<div class="flex xs1 text-center">Evento</div>
											<div class="flex xs2 text-center">Rastreio</div>
										</div>

										<div
											class="va-row lines"
											v-for="item in list"
											:key="item.id"
										>
											<div class="flex xs5 item">
												<div class="title-donation">
													<span class="donation-name">{{ item.name }}</span
													><br />
													<span class="created-data"
														>Início: {{ item.created }}</span
													>
												</div>
												<div class="va-row">
													<div class="flex xs6">Turmas: {{ item.grades }}</div>
													<div class="flex xs6">
														Livros: {{ item.books_completed }}/{{
															item.books_ordered
														}}<br />
													</div>
												</div>
											</div>

											<div
												class="flex xs1 item dates"
												:class="item.payment_class"
												v-if="!consultantCredential.settings.hide_payment_data"
											>
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.payment_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<i class="fa fa-check"></i>
													</div>
													<div class="flex xs12 item-text">
														{{ item.payment }}
													</div>
												</div>
											</div>
											<div
												class="flex xs1 item dates"
												:class="item.teacher_approval_class"
											>
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.teacher_approval_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<i class="fa fa-check"></i>
													</div>
													<div class="flex xs12 item-text">
														{{ item.teacher_approval }}
													</div>
												</div>
											</div>
											<div
												class="flex xs1 item dates"
												:class="item.production_class"
											>
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.production_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<i class="fa fa-check"></i>
													</div>
													<div class="flex xs12 item-text">
														{{ item.production }}
													</div>
												</div>
											</div>
											<div
												class="flex xs1 item dates"
												:class="item.delivery_class"
											>
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.delivery_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<i class="fa fa-check"></i>
													</div>
													<div class="flex xs12 item-text">
														{{ item.delivery }}
													</div>
												</div>
											</div>
											<div
												class="flex xs1 item dates"
												:class="item.deadline_class"
											>
												<div class="va-row">
													<div
														class="flex xs12 no-padding"
														:class="
															item.deadline_status === true
																? 'checked-item'
																: 'non-checked'
														"
													>
														<i class="fa fa-check"></i>
													</div>
													<div class="flex xs12 item-text">
														{{ item.deadline }}
													</div>
												</div>
											</div>

											<div class="flex xs2 item dates tracking-code">
												<a :href="item.tracking_code_url" target="_blank">{{
													item.tracking_code
												}}</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<infinite-loading
				v-if="filterLoaded"
				:identifier="[
					selectStatus,
					selectPayments,
					selectPaymentStatus,
					selectContracts,
				]"
				@infinite="getGrades"
			>
				<div slot="spinner"></div>
				<div slot="no-more"></div>
				<div slot="no-results"></div>
			</infinite-loading>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	// import VuesticTreeRoot from './VuesticTreeRoot.vue'
	// import VuesticTreeNode from './VuesticTreeNode'
	// import VuesticTreeCategory from './VuesticTreeCategory'

	const axios = require("axios");

	export default {
		name: "peoples",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				//userCredential: 'userCredential',
				consultantCredential: "consultantCredential",
				// ...
			}),
		},

		data() {
			return {
				filters: [],

				page: 1,
				list: [],

				emptyPeople: {
					name: "",
					email: "",
					role_id: 20,
					username: "",
					client_id: 1,
					products: null,
					school_id: null,
					grade_id: [],
				},

				nameSchool: "",

				listStatus: [],
				listPayments: [],
				listPaymentStatus: [],
				listContracts: [],

				filterLoaded: false,
				selectStatus: "",
				selectPayments: "",
				selectPaymentStatus: "",
				selectContracts: "",
			};
		},

		mounted() {
			const self = this;

			self.getFilters();
			//self.getGrades();
		},

		methods: {
			getFilters() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				axios
					.get(process.env.VUE_APP_ROOT_API + "/projects/filters", config)
					.then((response) => {
						self.filters = response.data.data;

						self.listStatus = response.data.data.status.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.listPayments = response.data.data.payments.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.listPaymentStatus = response.data.data.payment_status.map(
							(s) => ({
								key: s.id,
								value: s.name,
							})
						);

						self.listContracts = response.data.data.contracts.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.filterLoaded = true;
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			onChangeFilterStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectStatus = event.target.value;
				//this.getGrades()
			},

			onChangeFilterPayment(event) {
				this.page = 1;
				this.list = [];
				this.selectPayments = event.target.value;
				//this.getGrades()
			},

			onChangeFilterPaymentStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectPaymentStatus = event.target.value;
				//this.getGrades()
			},

			onChangeFilterContracts(event) {
				this.page = 1;
				this.list = [];
				this.selectContracts = event.target.value;
				//this.getGrades()
			},

			getGrades($state) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.consultantCredential.token,
					},
				};

				let url =
					"/dash/projects?page=" +
					self.page +
					"status=" +
					self.selectStatus +
					"&payments=" +
					self.selectPayments +
					"&payment_status=" +
					self.selectPaymentStatus +
					"&contracts=" +
					self.selectContracts;

				axios
					.get(process.env.VUE_APP_ROOT_API + url, config)
					.then((response) => {
						if (response.data.data.projects.length) {
							response.data.data.projects.forEach((element) => {
								self.list.push(element);
							});
							self.page += 1;
							$state.loaded();
						} else {
							$state.complete();
						}

						//self.list = response.data.data.projects
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.title-donation {
		padding: 10px 10px;
		padding-bottom: 0px;

		.donation-name {
			font-size: 16px;
		}
	}

	.created-data {
		font-size: 12px;
		display: block;
		color: #afa2ba;
	}
	.sets-list {
		font-size: 14px;

		.lines {
			width: 100%;

			border-bottom: solid 1px #dfdae4;

			.item {
				border-right: solid 1px #dfdae4;

				&.dates {
					display: -ms-flexbox;
					display: -webkit-flex;
					display: flex;

					-ms-flex-align: center;
					-webkit-align-items: center;
					-webkit-box-align: center;

					align-items: center;
					text-align: center;
					justify-content: center;

					.item-text {
						padding-top: 0px !important;
					}

					i {
						font-size: 25px;
					}

					.non-checked {
						color: rgba(0, 0, 0, 0.1);
					}
					.checked-item {
						i {
							color: #19dea3;
						}
					}
				}

				&.tracking-code {
					border-right: none !important;
					text-align: center;
					justify-content: center;

					a {
						text-align: center;
						justify-content: center;
						font-size: 16px;
						color: #4965bc;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		.header {
			background-color: #f8f7fc;
			border-bottom: solid 1px #dfdae4;
			color: #afa2ba;
			height: 61px;
			line-height: 45px;
			padding: 0px 20px;
			font-size: 12px;
		}

		::v-deep .select {
			font-size: 14px !important;
		}

		.full-line {
			margin: 0 auto;
			margin-bottom: 5px;
		}

		.flex {
			&.success {
				color: #000;
			}

			&.danger {
				background-color: #fff0f0;
			}
		}
	}

	.table-list {
		border: solid 1px #dfdae4;
		border-radius: 15px;
		overflow: hidden;
		margin-top: 15px !important;
	}

	.schedule-list {
		text-align: center;

		.bullet {
			display: flex;
			width: 110px;
			height: 110px;
			border-radius: 60px;
			margin: 0 auto;
			text-align: center;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}

		.blocks {
			padding: 10px 15px;
			margin-bottom: 15px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}
	}
	.btn-login {
		background-color: transparent;
		border: 0px;
		color: #4965bc;
	}
	.hide {
		display: none;
	}
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.fiscal-notes ::v-deep .form-group:last-child {
		margin-bottom: 10px !important;
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 30px 30px 10px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			.btn-change-edit {
				border: none;
				color: #4965bc;
				background: transparent;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	// label{
	//   color:#000;
	// }
</style>
